import { useUserStore } from '~/store/user'

export default defineNuxtRouteMiddleware(() => {
  const userStore = useUserStore()
  if (userStore.isGuest) {
    return navigateTo('/', {
      redirectCode: 302,
    })
  }
})
